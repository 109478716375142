<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.department')"
      filterable
      clearable
      :size="size"
      class="w-100"
		@change="Cchange"
    >
      <el-option
        v-for="(department, index) in departments"
        :key="'department-' + index"
        :label="department.name"
        :value="department.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    filial_ids: {
      default: null,
    },
  },
  watch: {
    id: {
      handler: function () {
			setTimeout(() => {this.selected = this.id}, 500);
      },
      immediate: true,
    },
	 filial_ids:{
		 handler: function () {
			if (this.filial_ids.length > 0)
        		this.updateInventory({filial_ids: this.filial_ids});
			else 
				this.$store.commit('departments/EMPTY_INVENTORY');
      },
		deep: true,
      immediate: true,
	 }
  },
  data() {
    return {
      selected: null,
    };
  },
//   mounted() {
//    if (this.filials && this.filials.length === 0) this.updateInventory({project_id: this.project_id});
//   },
  computed: {
    ...mapGetters({
      departments: "departments/inventory",
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "departments/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
	 Cchange(e){
		this.$emit('c-change', e);
		this.selected = e;
	 }
  },
};
</script>
